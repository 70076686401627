import Accordion from 'react-bootstrap/Accordion';

function ServiceTypes() {
  return (
    <>
      <Accordion className="accrdn d-none d-md-block" data-aos-once="true" data-aos="flip-up" data-aos-offset="300" data-aos-duration="2000" defaultActiveKey="2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Recruitment</Accordion.Header>
          <Accordion.Body>
              We specialize in identifying and attracting top talent tailored to meet your organization's specific needs, ensuring the perfect fit for your team.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Full-Time Employment</Accordion.Header>
          <Accordion.Body>
              Our dedicated team connects you with candidates seeking long-term career opportunities, providing a seamless hiring experience.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Permanent Contract</Accordion.Header>
          <Accordion.Body>
              We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Contract to Hire</Accordion.Header>
          <Accordion.Body>
              Our contract-to-hire service enables employers to evaluate candidates in a real-world setting before making a permanent hiring decision, reducing risks and enhancing fit.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Recruit the Recruiter</Accordion.Header>
          <Accordion.Body>
          We understand the importance of having exceptional recruitment professionals. Our service focuses on sourcing and placing top-tier recruiters to strengthen your hiring capabilities.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Resume Designing</Accordion.Header>
          <Accordion.Body>
              Our expert team assists candidates in crafting impactful resumes that highlight their skills and experiences, enhancing their chances of securing their desired roles.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Career Counselling</Accordion.Header>
          <Accordion.Body>
              We provide personalized career counselling to help individuals navigate their career paths, offering insights and strategies for achieving their professional goals.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>Training</Accordion.Header>
          <Accordion.Body>
              Our training programs focus on skill development and enhancing employability, equipping candidates with the knowledge and expertise needed to excel in today’s competitive job market.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion className="accrdn d-sm-block d-md-none" data-aos-once="true" data-aos="fade-up" data-aos-offset="300" data-aos-duration="2000" defaultActiveKey="2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Recruitment</Accordion.Header>
          <Accordion.Body>
              We specialize in identifying and attracting top talent tailored to meet your organization's specific needs, ensuring the perfect fit for your team.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Full-Time Employment</Accordion.Header>
          <Accordion.Body>
              Our dedicated team connects you with candidates seeking long-term career opportunities, providing a seamless hiring experience.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Permanent Contract</Accordion.Header>
          <Accordion.Body>
              We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Contract to Hire</Accordion.Header>
          <Accordion.Body>
              Our contract-to-hire service enables employers to evaluate candidates in a real-world setting before making a permanent hiring decision, reducing risks and enhancing fit.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Recruit the Recruiter</Accordion.Header>
          <Accordion.Body>
          We understand the importance of having exceptional recruitment professionals. Our service focuses on sourcing and placing top-tier recruiters to strengthen your hiring capabilities.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Resume Designing</Accordion.Header>
          <Accordion.Body>
              Our expert team assists candidates in crafting impactful resumes that highlight their skills and experiences, enhancing their chances of securing their desired roles.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Career Counselling</Accordion.Header>
          <Accordion.Body>
              We provide personalized career counselling to help individuals navigate their career paths, offering insights and strategies for achieving their professional goals.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>Training</Accordion.Header>
          <Accordion.Body>
              Our training programs focus on skill development and enhancing employability, equipping candidates with the knowledge and expertise needed to excel in today’s competitive job market.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default ServiceTypes;