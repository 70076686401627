import { FaMapMarkerAlt } from "react-icons/fa"
import { MdLocationPin } from "react-icons/md"

export const Map = () => {
    return (
        <>
            {/* Desktop Sect. */}
            <section className="map pt-5 d-none d-md-block">
                <h3 className="sectTitle text-center">Our Presence</h3>
                <div className="contain text-center">
                    <img src={require('./img/map.webp')} alt="" />
                    <div data-aos="zoom-out-down" data-aos-once="true" data-aos-duration="2000" className="pointer usa"><MdLocationPin className="icons" /><p>USA</p></div>
                    <div data-aos="zoom-out-down" data-aos-once="true" data-aos-duration="2000" className="pointer europe"><MdLocationPin className="icons" /><p>Europe</p></div>
                    <div data-aos="zoom-out-down" data-aos-once="true" data-aos-duration="2000" className="pointer uae"><MdLocationPin className="icons" /><p>UAE</p></div>
                    <div data-aos="zoom-out-down" data-aos-once="true" data-aos-duration="2000" className="pointer india"><MdLocationPin className="icons" /><p>India</p></div>
                    <div data-aos="zoom-out-down" data-aos-once="true" data-aos-duration="2000" className="pointer newzealand"><MdLocationPin className="icons" /><p>New Zealand</p></div>
                </div>
                <div className="d-flex mapdetails">
                    <FaMapMarkerAlt className="icons text-center" /><span><b>Right Careers Headquarters</b><br />Mumbai, India </span>
                    <span style={{ width: 30 }}></span>
                    <FaMapMarkerAlt className="icons greenIcon text-center" /><span><b>Right Careers Office Locations:</b><br />India - Mumbai, UAE,  USA , New Zealand, Europe </span>
                </div>
            </section>
            {/* Mob section */}
            <section className="mapBg d-sm-block d-md-none">
                <h3 className="sectTitle text-center">Our Presence</h3>
            </section>
        </>
    )
}