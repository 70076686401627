import './timeline.css'
export const Timeline = () => {
    return (
        <>
            {/* BEGIN : timeline */}
            <section className='py-5'>
                <h3 data-aos="fade-up" className="text-center pb-4 mb-3 sectTitle">Our Journey</h3>
                <div class="app-timeline-wrapper py-5">
                    <div class="app-timeline">
                        <span class="app-timeline-display-line"></span>
                        {/* BEGIN: card bottom align */}
                        <div  class="app-timeline-card-wrapper app-timeline-card-align-bottom app-timeline-green-box">
                            <span class="app-timeline-dot"></span>
                            <div data-aos-once="true" data-aos="fade-down" data-aos-duration="2000" class="app-time-line-card">
                                <h6 class="app-timeline-date">Our First Step</h6>
                                <p class="app-timeline-text">Journey Begins</p>
                            </div>
                        </div>
                        {/* END: card bottom align */}
                        {/* BEGIN: card 2 top align */}
                        <div class="app-timeline-card-wrapper app-timeline-card-align-top top1 app-timeline-blue-box">
                            <span class="app-timeline-dot"></span>
                            <div data-aos-once="true" data-aos="fade-up" data-aos-duration="2000" class="app-time-line-card">
                                <h6 class="app-timeline-date">Azucane</h6>
                                <p class="app-timeline-text">1 st Transation With </p>
                                <img src={require('./img/EY.webp')} alt="" />
                            </div>
                        </div>
                        {/* END: card bottom align */}
                        {/* BEGIN: card 3 bottom align */}
                        <div class="app-timeline-card-wrapper app-timeline-card-align-bottom bottom2 app-timeline-green-box">
                            <span class="app-timeline-dot"></span>
                            <div data-aos-once="true" data-aos="fade-down" data-aos-duration="3000" class="app-time-line-card">
                                <h6 class="app-timeline-date">5 Million USD Revenue Achieved</h6>
                                <p class="app-timeline-text">2nd Transation With JSS </p>
                                <img src={require('./img/JSS.webp')} alt="" />
                            </div>
                        </div>
                        {/* END: card bottom align */}
                        {/* BEGIN: card 4 bottom align */}
                        <div class="app-timeline-card-wrapper app-timeline-card-align-top top2 app-timeline-blue-box">
                            <span class="app-timeline-dot"></span>
                            <div data-aos-once="true" data-aos="fade-up" data-aos-duration="3000" class="app-time-line-card">
                                <h6 class="app-timeline-date">Right Careers Offices</h6>
                                <p class="app-timeline-text">in UAE, UK, USA & NZ</p>
                            </div>
                        </div>
                        {/* END: card 4 bottom align */}
                        {/* BEGIN: card 5 bottom align */}
                        <div class="app-timeline-card-wrapper app-timeline-card-align-bottom bottom3 app-timeline-green-box">
                            <span class="app-timeline-dot"></span>
                            <div data-aos-once="true" data-aos="fade-down" data-aos-duration="3000" class="app-time-line-card">
                                <h6 class="app-timeline-date">Key Milestones</h6>
                                <p class="app-timeline-text">
                                    - 3 Government Accounts<br />
                                    - Started Cyber Security with
                                    Global Presence<br />
                                    - 1 Million Database of Talents<br />
                                    -100k Successful Talent Assets
                                </p>
                            </div>
                        </div>
                        {/* END: card 5 bottom align */}
                        {/* BEGIN: card 6 bottom align */}
                        <div class="app-timeline-card-wrapper app-timeline-card-align-top top3 app-timeline-blue-box">
                            <span class="app-timeline-dot"></span>
                            <div data-aos-once="true" data-aos="fade-up" data-aos-duration="3000" class="app-time-line-card">
                                <h6 class="app-timeline-date">50 million UAE</h6>
                                <p class="app-timeline-text">partnership with UEM’s</p>
                            </div>
                        </div>
                        {/* END: card 6 bottom align */}
                        {/* BEGIN: card 7 bottom align */}
                        <div class="app-timeline-card-wrapper app-timeline-card-align-bottom bottom4 app-timeline-green-box">
                            <span class="app-timeline-dot"></span>
                            <div data-aos-once="true" data-aos="fade-down" data-aos-duration="3000" class="app-time-line-card">
                                <h6 class="app-timeline-date">Future Goals</h6>
                                <p class="app-timeline-text">
                                    - Multi Domain Expertise<br />
                                    - $15-20 Million USD<br />
                                    - State Of the art Development
                                </p>
                            </div>
                        </div>
                        {/* END: card 7 bottom align */}
                    </div>
                </div>
            </section>
            {/* END : timeline */}
        </>
    )
}