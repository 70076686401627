import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './joinus.css';
import BasicExample from "./BasicExample.jsx";
import { Footer } from "./Footer";
import { Map } from "./Map.js";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Employee } from "./Employee.jsx";
import { Employer } from "./Employer.jsx";

export const Connect = () => {
    // useState variable for phone field top side of the form
    const [value, setValue] = useState()

    // useState for whether it is employer or employee
    const [user, setUser] = useState()

    useEffect(() => {
        AOS.init();
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    // Select field value //

    const handleChange = (e) => {
        setUser(e.target.value)
        console.log(user)
    }

    return (
        <>
            <Header />
            <div className="banner bg-img-connect">
                <div className="bdcrumb"><Link to="/">Home</Link> | About | Connect with Us</div>
                <span className="sm-highlight">Connect with Us</span>  
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Looking to connect? <br /><span className="subhead" style={{ fontWeight: 400 }}>Here’s how we can assist you</span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            <section ref={ref} className='p-5 w80 connectSect'>
                <h3 className="text-center sectTitle pb-5">Corporate Office</h3>
                <div className="container">
                    <div className="presence">
                        <div data-aos="fade-right" data-aos-duration="2000" className="block block1">
                            <h5 className="title">Mumbai Office</h5>
                            <p className="address">Office No. 112, 1st Floor,
                                D-Wing MBC Infotech Park,<br /> Ghodbunder Road,
                                Kasarvadavli, Thane – 400615.<br /> Maharashtra, India.
                            </p>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="2000" className="block block2">
                            <h5 className="title">Connect us</h5>
                            <p className="connectAddress">
                                <span className="">Tel:</span> 022 -48016256 / <a href="tel:9307416089">9307416089</a><br />
                                <span className="">Email:</span><a href="mailto:sales@rightcareers.co.in"> sales@rightcareers.co.in</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="connectForm py-4 d-none d-md-block">
                <h3 data-aos="fade-up" className="text-center sectTitle pb-5">Contact Us</h3>
                <div className="container">
                    <form data-aos="zoom-in" data-aos-duration="2000" onSubmit={handleSubmit}>
                        <div className="upperFrmSect">
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">First Name</label>
                                <input className="inpt" type="text" required />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Last Name</label>
                                <input className="inpt" type="text" required />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Contact Number</label>
                                <PhoneInput
                                    country={'us'}
                                    international
                                    defaultCountry="IN"
                                    value={value}
                                    onChange={setValue}
                                    required
                                />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Email Id</label>
                                <input className="inpt" type="email" />
                            </div>
                        </div>
                        <div className="userSect py-3">
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">You wish to connect as*</label>
                                <select className="inpt" onChange={handleChange}>
                                    <option>Please Select</option>
                                    <option value='jobSeeker'>Job Seeker</option>
                                    <option value='employer'>Employer</option>
                                </select>
                            </div>
                            <div className="inptBlock col-md-5 mt-4"></div>
                            {console.log('current value of selected stuff ' + user)}
                            {user == "jobSeeker" ? <Employee /> : ""}
                            {user == "employer" ? <Employer /> : ""}
                            <div className="text-center pt-3"><button className="submitBtn" type="submit">Submit</button></div> 
                        </div>
                    </form>
                </div>
            </section>
            <section className="connectForm py-4 d-sm-block d-md-none">
                <h3 data-aos="fade-up" className="text-center sectTitle pb-5">Contact Us</h3>
                <div className="container">
                    <form data-aos="zoom-in" data-aos-duration="2000" onSubmit={handleSubmit}>
                        <div className="upperFrmSect">
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">First Name</label>
                                <input className="inpt" type="text" required />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Last Name</label>
                                <input className="inpt" type="text" required />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Contact Number</label>
                                <PhoneInput
                                    country={'us'}
                                    international
                                    defaultCountry="IN"
                                    value={value}
                                    onChange={setValue}
                                    required
                                />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Email Id</label>
                                <input className="inpt" type="email" />
                            </div>
                        </div>
                        <div className="userSect py-3">
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">You wish to connect as*</label>
                                <select className="inpt" onChange={handleChange}>
                                    <option>Please Select</option>
                                    <option value='jobSeeker'>Job Seeker</option>
                                    <option value='employer'>Employer</option>
                                </select>
                            </div>
                            <div className="inptBlock col-md-5 mt-4"></div>
                            {console.log('current value of selected stuff ' + user)}
                            {user == "jobSeeker" ? <Employee /> : ""}
                            {user == "employer" ? <Employer /> : ""}
                            <div className="text-center pt-3"><button className="submitBtn" type="submit">Submit</button></div> 
                        </div>
                    </form>
                </div>
            </section>
            <Map />
            <Footer />
        </>
    )
}