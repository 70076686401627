import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AboutOverview } from './About-overview';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Demo } from './Demo';
import { JoinUs } from './JoinUs.js';
import { Connect } from './Connect.jsx';
import { Services } from './Services.jsx';
import { Captcha } from './Captcha.jsx';
import { Automobile } from './Automobile.jsx';
import { Banking } from './Banking.jsx';
import { Energy_Infrastructure } from './Energy_Infrastructure.jsx';
import { Financial_Professional } from './Financial_Professional.jsx';
import { Healthcare } from './Healthcare.jsx';
import { Hospitality } from './Hospitality.jsx';
import { Industrial_Engineering } from './Industrial_Engineering.jsx';
import { Information_Technology } from './Information_Technology.jsx';
import { Lifestyle } from './Lifestyle.jsx';
import { Logistics } from './Logistics.jsx';
import { Media_Entertainment } from './Media_Entertainment.jsx';
import { Practice } from './Practice.jsx';
import { Policy } from './Privacy-policy.jsx';
import { Coockies } from './Cookies-policy.jsx';
import { Terms } from './Terms.jsx';
import { Disclaimer } from './Disclaimer.jsx';

 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about-overview" element={<AboutOverview />} />
        <Route path="/join-us" element={<JoinUs />} />
        <Route path='/demo' element={<Demo />} />
        <Route path='/connect-with-us' element={<Connect />} />
        <Route path='/connect-with-us2' element={<Connect />} />
        <Route path='/services' element={<Services />} />
        <Route path='/captcha' element={<Captcha />} />
        <Route path='/automobile' element={<Automobile />} />
        <Route path='/banking' element={<Banking />} />
        <Route path='/energy_infrastructure' element={<Energy_Infrastructure />} />
        <Route path='/financial_professional' element={<Financial_Professional />} />
        <Route path='/healthcare' element={<Healthcare />} />
        <Route path='/hospitality' element={<Hospitality />} />
        <Route path='/industrial_engineering' element={<Industrial_Engineering />} />
        <Route path='/information_technology' element={<Information_Technology />} />
        <Route path='/lifestyle' element={<Lifestyle />} />
        <Route path='/logistics' element={<Logistics />} />
        <Route path='/media_entertainment' element={<Media_Entertainment />} />
        <Route path='/practice' element={<Practice />} />
        <Route path='/privacy-policy' element={<Policy />} />
        <Route path='/cookies-policy' element={<Coockies />} />
        <Route path='/terms-and-conditions' element={<Terms />} />
        <Route path='/disclaimer' element={<Disclaimer />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>       
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();