import Container from 'react-bootstrap/Container';
import './menu.css'
import { MdKeyboardArrowDown } from "react-icons/md";
import { a } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { ReactComponent as Facebook } from './img/svg/facebook_logo_icon.svg'
import { ReactComponent as Instagram } from './img/svg/instagram_logo_icon.svg'
import { ReactComponent as Youtube } from './img/svg/youtube_videos_icon.svg'
import { ReactComponent as Linkedin } from './img/svg/linkedin_logo_icon.svg'
import Pdf1 from './pdfs/Corporate_Stewardship_Policy.pdf';
import Pdf2 from './pdfs/Data Privacy Framework.pdf';
import Pdf3 from './pdfs/Environment Safety Policy.pdf';
import Pdf4 from './pdfs/Gifts, Hospitality and Ethical Conduct.pdf';
import Pdf5 from './pdfs/Operational Resilience Policy.pdf';
import Pdf6 from './pdfs/Workplace Dignity and Respect Policy.pdf';

export const Header = () => {
    const [about, setAbout] = useState(false);
    const [policy, setPolicy] = useState(false);
    const [industries, setIndustries] = useState(false);
    const [close, setClose] = useState(false);

    return (
        <>
            {/* Desktop Header */}
            <div className="container-fluid d-none d-md-block">
                <div className="newHeader">
                    <a href="/"><img alt='Client Logo' src='right_career_home1.webp' /></a>
                    <div className="menu">
                        <div className="menuItem dropDown">
                            <a className="">About Us<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox">
                                <a href="/about-overview">Overview</a>
                                <a href="/join-us">Join Us</a>
                                <a href="/connect-with-us">Connect with Us</a>
                            </div>
                        </div>
                        <div className="menuItem dropDown ">
                            <a className="">Industries<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox bgdd">
                                <a href="/automobile">Automobile</a>
                                <a href="/banking">Banking</a>
                                <a href="/energy_infrastructure">Energy & Infrastructure</a>
                                <a href="/financial_professional">Financial Professional</a>
                                <a href="/healthcare">Healthcare</a>
                                <a href="/hospitality">Hospitality</a>
                                <a href="/industrial_engineering">Industrial Engineering</a>
                                <a href="/information_technology">Information Technology</a>
                                <a href="/lifestyle">Lifestyle</a>
                                <a href="/logistics">Logistics</a>
                                <a href="/media_entertainment">Media & Entertainment</a>
                            </div>
                        </div>
                        <div ><a className="menuItem" style={{ textDecoration: 'none' }} href="/services">Services</a></div>
                    </div>
                </div>
            </div>
            {/* Mobile Header */}
            <div className="container-fluid d-sm-block d-md-none">
                <div className="newHeader mobHeader">
                    <a href="/" className='imgUrl'><img alt='Client Logo' src='right_career_home1.webp' /></a>
                    <RxHamburgerMenu onClick={() => setClose(true)} className={close ? "d-none" : "menuIcon"} />
                    <RxCross2 onClick={() => setClose(false)} className={close ? "menuIcon close" : "d-none"} />
                    <div className="menu mobMenu">
                        <div onClick={() => { setAbout(!about); setIndustries(false); setPolicy(false) }} className={about ? "active menuItem" : "menuItem dropDown"}>
                            <a className="">About Us<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox">
                                <a href="/about-overview">Overview</a>
                                <a href="/join-us">Join Us</a>
                                <a href="/connect-with-us">Connect with Us</a>
                            </div>
                        </div>
                        <div onClick={() => { setAbout(false); setIndustries(!industries); setPolicy(false) }} className={industries ? "active menuItem" : "menuItem dropDown"}>
                            <a className="">Industries<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox bgdd">
                                <a href="/automobile">Automobile</a>
                                <a href="/banking">Banking</a>
                                <a href="/energy_infrastructure">Energy & Infrastructure</a>
                                <a href="/financial_professional">Financial Professional</a>
                                <a href="/healthcare">Healthcare</a>
                                <a href="/hospitality">Hospitality</a>
                                <a href="/industrial_engineering">Industrial Engineering</a>
                                <a href="/information_technology">Information Technology</a>
                                <a href="/lifestyle">Lifestyle</a>
                                <a href="/logistics">Logistics</a>
                                <a href="/media_entertainment">Media & Entertainment</a>
                            </div>
                        </div>
                        <div className="menuItem"><a style={{ textDecoration: 'none' }} href="/services">Services</a></div>
                        {/* <div className="menuItem"><a className="">Blog</a></div> */}
                        <div className="col-md-4 policies">
                            <div onClick={() => { setPolicy(!policy); setIndustries(false); setAbout(false) }} className={policy ? "active plcy menuItem" : "menuItem policydwn"}>
                                <a className="">Policies For Right Careers<MdKeyboardArrowDown className='dropdownIcon' /></a>
                                <div className="dropwdownBox policyDD">
                                    <a href={Pdf1 + "#toolbar=0"} target="_blank">Corporate Stewardship Policy</a>
                                    <a href={Pdf2 + "#toolbar=0"} target="_blank">Data Privacy Framework</a>
                                    <a href={Pdf3 + "#toolbar=0"} target="_blank">Environment Safety policy</a>
                                    <a href={Pdf4 + "#toolbar=0"} target="_blank">Gifts, Hospitality & Ethical conduct</a>
                                    <a href={Pdf5 + "#toolbar=0"} target="_blank">Operational Resilience Policy</a>
                                    <a href={Pdf6 + "#toolbar=0"} target="_blank">Workplace Dignity and Respect Policy</a>
                                </div>
                            </div>
                        </div>
                        <div className="socialMedia mt-3 mx-auto">
                        </div>
                        <p className="mb-1 flw "><b>Follow Us</b></p>
                        <div className="socialIcons pb-3">
                            <a target="_blank" href="https://www.linkedin.com/company/right-careers-in/"><i class="fa fa-brands fa-linkedin"></i></a>
                            <a target="_blank" href="https://www.facebook.com/rightcareersoffical?mibextid=ZbWKwL"><i className="fa fa-brands fa-facebook-f"></i></a>
                            <a target="_blank" href="https://www.instagram.com/rightcareers?igsh=MTNzeGttYTFzMWVtNw"><i class="fa fa-brands fa-instagram"></i></a>
                            <a target="_blank" href="https://www.youtube.com/@Right.Careers"><i class="fa fa-brands fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}