import Container from 'react-bootstrap/Container';
import './menu.css'
import { MdKeyboardArrowDown } from "react-icons/md";
import { a } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from 'react';
import { RxCross2 } from "react-icons/rx";

export const Header = () => {
    const [about, setAbout] = useState(false);
    const [industries, setIndustries] = useState(false);
    const [close, setClose] = useState(false);
    
    return (
        <>
        {/* Desktop Header */}
            <div className="container-fluid d-none d-md-block">
                <div className="newHeader">
                    <a href="/"><img alt='Client Logo' src='right_career_home1.webp' /></a>
                    <div className="menu">
                        <div className="menuItem dropDown">
                            <a className="">About Us<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox">
                                <a href="/about-overview">Overview</a>
                                <a href="/join-us">Join Us</a>
                                <a href="/connect-with-us">Connect with Us</a>
                            </div>
                        </div>
                        <div className="menuItem dropDown ">
                            <a className="">Industries<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox bgdd">
                                <a href="/automobile">Automobile</a>
                                <a href="/banking">Banking</a>
                                <a href="/energy_infrastructure">Energy & Infrastructure</a>
                                <a href="/financial_professional">Financial Professional</a>
                                <a href="/healthcare">Healthcare</a>
                                <a href="/hospitality">Hospitality</a>
                                <a href="/industrial_engineering">Industrial Engineering</a>
                                <a href="/information_technology">Information Technology</a>
                                <a href="/lifestyle">Lifestyle</a>
                                <a href="/logistics">Logistics</a>
                                <a href="/media_entertainment">Media & Entertainment</a>
                            </div>
                        </div>
                        <div ><a className="menuItem" style={{textDecoration: 'none'}} href="/services">Services</a></div>
                    </div>
                </div>
            </div>
        {/* Mobile Header */}
            <div className="container-fluid d-sm-block d-md-none">
                <div className="newHeader mobHeader">
                    <a href="/" className='imgUrl'><img alt='Client Logo' src='right_career_home1.webp' /></a>
                    <RxHamburgerMenu onClick={() => setClose(true)} className={close ? "d-none" : "menuIcon"} />
                    <RxCross2 onClick={() => setClose(false)} className={close ? "menuIcon close" : "d-none"} />
                    <div className="menu mobMenu">
                        <div onClick={() => {setAbout(!about); setIndustries(false)}} className={about? "active menuItem" : "menuItem dropDown"}>
                            <a className="">About Us<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox">
                                <a href="/about-overview">Overview</a>
                                <a href="/join-us">Join Us</a>
                                <a href="/connect-with-us">Connect with Us</a>
                            </div>
                        </div>
                        <div onClick={() => {setAbout(false); setIndustries(!industries)}} className={industries? "active menuItem" : "menuItem dropDown"}>
                            <a className="">Industries<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox bgdd">
                                <a href="/automobile">Automobile</a>
                                <a href="/banking">Banking</a>
                                <a href="/energy_infrastructure">Energy & Infrastructure</a>
                                <a href="/financial_professional">Financial Professional</a>
                                <a href="/healthcare">Healthcare</a>
                                <a href="/hospitality">Hospitality</a>
                                <a href="/industrial_engineering">Industrial Engineering</a>
                                <a href="/information_technology">Information Technology</a>
                                <a href="/lifestyle">Lifestyle</a>
                                <a href="/logistics">Logistics</a>
                                <a href="/media_entertainment">Media & Entertainment</a>
                            </div>
                        </div>
                        <div className="menuItem"><a style={{textDecoration: 'none'}} href="/services">Services</a></div>
                        {/* <div className="menuItem"><a className="">Blog</a></div> */}
                    </div>
                </div>
            </div>
        </>
    )
}