import { Component2 } from "./Component2"

export const Practice = () => {

    return <>
        <div className="text-center">
            <p>hello world</p>
            <Component2 />
        </div>
    </>
}