import { useEffect, useRef, useState } from "react";
import img1 from "./img/be1.webp"

export const Component2 = () => {
    const abc = useRef(0)
    const a = [
        {
            a: 'a',
            link: "https://lkdgr8.github.io/my_portfolio",
            img: img1
        },
        {
            a: 'b',
            link: "https://lkdgr8.github.io/my_portfolio"
        },
        {
            a: 'c',
            link: "https://lkdgr8.github.io/my_portfolio"
        },
        {
            a: 'd',
            link: "https://lkdgr8.github.io/my_portfolio"
        },
        {
            a: 'e',
            link: "https://lkdgr8.github.io/my_portfolio"
        },
        {
            a: 'f',
            link: "https://lkdgr8.github.io/my_portfolio"
        }
    ]
    const [abcd, setAbcd] = useState(0);

    useEffect(() => {
        abc.current = abc.current + 1;
        setAbcd((abcd) => abcd + 1);
    });

    return <>
        <input type="text" />
        <input type="submit" />
        <ul>
            {
                a.map((newarray, index) => {
                    return (
                        <li key={index}>
                            <span>{newarray.a} </span>
                            <a target="/blank" href={newarray.link}>Link</a>
                            <img src={newarray.img} alt="" />
                        </li>
                    )
                })
            }
        </ul>
        <button>Start the counter</button>
        {/* <p>Ek plate wada + {abcd}</p> */}
        <p>{abc.current}</p>
        <p>{abcd}</p>
    </>
}