import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer.js";
import './energy_infra.css'
import './services.css'
import './industries.css'
import ServiceTypes from "./ServiceTypes.jsx";
import Cards from "./Cards.jsx"
import { Testimonial } from "./Testimonial.js";

export const Energy_Infrastructure = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    const ref = useRef()

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg_ener">
                <div className="bdcrumb"><a href="/">Home</a> | Industries | Energy & Infrastructure</div>
                <span className="sm-highlight">Energy & Infrastructure</span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Sustainable Growth Starts  <br /><span className="subhead" style={{ fontWeight: 400 }}>with the Right Talent </span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* about overview section on home page */}
            <section className="clients my-5" ref={ref}>
                <div className="industry headings">
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="mx-auto industrial mytext">The energy and infrastructure industry is the backbone of our society, powering homes, businesses, and economies. As this sector evolves, it faces challenges and opportunities that demand a skilled workforce. Effective recruitment is essential in finding the right talent—whether innovative engineers, experienced project managers, or passionate sustainability experts—to ensure successful projects that benefit communities.</h2>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">At Right Careers, we understand the unique needs of this dynamic industry. We’re committed to helping organizations connect with the best talent out there, individuals who not only have the technical skills but also share a vision for a sustainable future. By building strong, capable teams, we enable businesses to tackle today’s challenges and embrace tomorrow’s possibilities. Together, we can drive the energy and infrastructure sector forward, creating a brighter, more efficient world for everyone.</p>
      
                </div>
            </section>
            <section className="indSrvc pt-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Services</h3>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon1.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Talent Acquisition</h5>
                                    <p>Identifying and attracting top candidates to fit organizational roles.&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon2.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Executive Search</h5>
                                    <p>Locating senior-level leaders with vision and strategic expertise.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon3.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Temporary Staffing</h5>
                                    <p>Providing flexible, short-term talent solutions for immediate business needs.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon4.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Recruitment Process
                                        Outsourcing (RPO)</h5>
                                    <p>Managing the entire recruitment process, ensuring efficiency and quality hires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 right  rghtSect">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon5.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Employer Branding</h5>
                                    <p>Enhancing company reputation to attract and retain top talent.</p>
                                </div>
                            </div>
                            <img data-aos="fade-right" data-aos-duration="1000" className="lady" src={require('./img/industrial_serivce.webp')} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="staffing py-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Staffing Solutions</h3>
                <div className="container py-4">
                    <h5 className="sm-title">A. Traditional Energy (Oil, Gas, Coal)</h5>
                    <div className="cards">
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/energy1.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Operations & Engineering Roles</h5>
                                <p>
                                    - Petroleum Engineer<br />
                                    - Drilling Engineer<br />
                                    - Production Engineer (Oil & Gas)<br />
                                    - Mechanical Engineer (Energy)<br />
                                    -  Pipeline Engineer
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/energy2.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Health, Safety & Environmental (HSE)</h5>
                                <p>
                                    - HSE Manager<br />
                                    - Environmental Engineer
                                </p>
                            </div>
                        </div>
                    </div>
                    <h5 className="sm-title smt2">B. Renewable Energy<br />(Wind, Solar, Hydro, Biomass)</h5>
                    <div className="cards">
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/energy3.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Energy Trading & Finance</h5>
                                <p>
                                    - Energy Trader<br />
                                    - Energy Risk Manager
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/energy4.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Engineering and Technical Roles</h5>
                                <p>
                                    - Renewable Energy Engineer<br />
                                    - Solar PV Technician/Engineer<br />
                                    - Wind Turbine Technician/Engineer<br />
                                    - Hydropower Engineer<br />
                                    - Energy Storage Engineer
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="clients py-5">
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center sectTitle">Some of Our Clients</h3>
                <div className="text-center">
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/industry_clients/engergy_infrastructure.webp')} alt="" />
                </div>
            </section>
            <Testimonial />
            <section className="indstats">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/Indvid.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent indRows py-5">
                    <p data-aos="fade-right" data-aos-duration="500"><span className="boldText">5K+</span> Successful<br /> Recruits Yearly</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1000">Assured <span className="boldText">2:2</span><br />Hiring Plan </p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1500">Consistent Track record of<br /><span className="boldText">95%</span> On-Time Delivery</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="2000" className="nobr"><span className="boldText">Skill-Based Training</span><br /> with Certified Industry Experts</p>
                </div>
            </section>
            <Footer />
        </>
    )
}