import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer";
import './services.css'
import ServiceTypes from "./ServiceTypes.jsx";

export const Services = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()
    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg">
                <div className="bdcrumb"><Link to="/">Home</Link> | Services</div>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">The Right Choice for <br /><span className="subhead" style={{ fontWeight: 400 }}>Your Hiring Needs </span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* about overview section on home page */}
            <section data-aos="fade-up" data-aos-easing="ease-in-sine" ref={ref} style={{ backgroundColor: '#ef8f22' }} className='p-5 w80 sect-overview services'>
                <p className='container over-text'>
                    At Right Careers, we optimize your hiring process for maximum efficiency and productivity, using real-time data analytics to make informed decisions. With our Consultative Talent Assessment Process, we deliver top talent with zero lead time, ensuring your business keeps moving forward.
                </p>
            </section>
            <section className="clients my-5">
                <div className="headings">
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="text-center mytext">The Trusted Choice of Fortune 500</h2>
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="text-center mytext2">Companies and Businesses Globally</h2>
                    <div className="container text-center">
                        <div data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_1.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_2.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_3.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_4.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_5.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_6.jpg')} alt="Right careers clients" />
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_7.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_8.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_9.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_10.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_11.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_12.jpg')} alt="Right careers clients" />
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_13.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_14.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_15.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_16.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_17.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_18.jpg')} alt="Right careers clients" />
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_19.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_20.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_21.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_22.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_23.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_24.jpg')} alt="Right careers clients" />
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_25.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_26.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_27.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_28.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_29.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_30.jpg')} alt="Right careers clients" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="vision srvcGrntee d-none d-md-flex flex-column">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/Service1.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent">
                    <h2 data-aos="fade-down" className="" style={{ color: '#fff', fontWeight: "600", fontSize: "35px" }}>Our proven 95% on-time delivery success rate makes us the backbone of hiring for Fortune 500 companies globally.</h2>
                </div>
            </section>
            {/* srvc blue mobile start */}
            <section className="vision srvcGrntee d-sm-block d-md-none">
                <div className="visionContent">
                    <h2 data-aos="fade-down" className="" style={{ color: '#fff', fontWeight: "600", fontSize: "35px" }}>Our proven 95% on-time delivery success rate makes us the backbone of hiring for Fortune 500 companies globally.</h2>
                </div>
            </section>
            <section className="py-5 d-none d-md-flex">
                <div className="container">
                    <div className="row">
                        <div data-aos="fade-left" data-aos-duration="1500" className="col-md-7 rghtSect">
                            <p className="mb-0">
                                We dive deep into your unique business needs, creating a customized hiring strategy to find the perfect fit for each role.<br /><br />
                                We enhance talent with tailored skill-based training programs led by certified industry experts, ensuring your team stays ahead of the curve.<br /><br />
                                We transform recruitment by broadening search parameters, enhancing your employer brand, and showcasing growth opportunities.
                                By connecting with the right talent through social media and educational partnerships, we invest in employee development and maintain a robust talent pipeline to experience flexibility and a positive candidate journey for building a brighter future together.
                            </p>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="1000" className="col-md-5 lftSect d-flex">
                            <p className="lftTitle align-self-center">Our innovative R2R model, powered by AI-HR tools, drives hypergrowth in ROI by up to 50%, with an unbeatable 2:2 Hiring Plan—two pre-screened candidates delivered within 48 hours.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* srcv content section */}
            <section className="py-5 d-sm-block d-md-none">
                <div className="container">
                    <div className="row">
                        <div data-aos="fade-up" data-aos-duration="1500" className="col-md-7 rghtSect">
                            <p className="mb-0">
                                We dive deep into your unique business needs, creating a customized hiring strategy to find the perfect fit for each role.<br /><br />
                                We enhance talent with tailored skill-based training programs led by certified industry experts, ensuring your team stays ahead of the curve.<br /><br />
                                We transform recruitment by broadening search parameters, enhancing your employer brand, and showcasing growth opportunities.
                                By connecting with the right talent through social media and educational partnerships, we invest in employee development and maintain a robust talent pipeline to experience flexibility and a positive candidate journey for building a brighter future together.
                            </p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-5 lftSect d-flex">
                            <p className="lftTitle align-self-center">Our innovative R2R model, powered by AI-HR tools, drives hypergrowth in ROI by up to 50%, with an unbeatable 2:2 Hiring Plan—two pre-screened candidates delivered within 48 hours.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="d7r d-none d-md-block">
                <div className="container">
                    <p className="rs7 button">Our 7 R's  that Makes Us Your Right Choice </p>
                    <div className="elipses">
                        <div className="elipse elipse1">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon1.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">1</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Recruit</h5>
                                    <p>The Recruiter</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse2">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon2.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">2</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Rated as</h5>
                                    <p>'Preferred Choice' by global 'Job Seekers & Job Creators'</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse3">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon3.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">3</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Redefined</h5>
                                    <p>Pricing breaking the industry standards</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse4">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon4.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">4</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Real</h5>
                                    <p>Time Data Analysis</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse5">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon5.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">5</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Reduced</h5>
                                    <p>Time On Hiring</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse6">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon6.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">6</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Right Tools </h5>
                                    <p>AI Backed Search Engine, 2.5 million + In house Talent database</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse7">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon7.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">7</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">ROI Driven</h5>
                                    <p>Result Oriented</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* 7 Stage mob */}
            <section className="d7r d-sm-block d-md-none">
                <div className="container">
                    <p className="rs7 button">Our 7 R's  that Makes Us Your Right Choice </p>
                    <div className="elipses">
                        <div className="elipse elipse1">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" src={require('./img/services_icons/icon1.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">1</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Recruit</h5>
                                    <p>the Recruiter</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse2">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" src={require('./img/services_icons/icon2.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">2</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Rated as</h5>
                                    <p>'Preferred Choice' by global 'Job Seekers & Job Creators'</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse3">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" src={require('./img/services_icons/icon3.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">3</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Redefined</h5>
                                    <p>Pricing breaking the industry standards</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse4">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" src={require('./img/services_icons/icon4.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">4</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Real</h5>
                                    <p>time Data Analysis</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse5">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" src={require('./img/services_icons/icon5.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">5</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Reduced</h5>
                                    <p>Time On Hiring</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse6">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" src={require('./img/services_icons/icon6.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">6</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Right Tools </h5>
                                    <p>AI Backed Search Engine, 2.5 million + In house Talent database</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse7">
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" data-aos-once="true" src={require('./img/services_icons/icon7.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" data-aos-once="true" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">7</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">ROI Driven</h5>
                                    <p>Result Oriented</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* 10 Stage Process */}
            <section className="process py-5 d-none d-md-block">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle stgs">What to expect during Our Hiring Process</h3>
                <section className="stages container">
                    {/* <img src={require('./img/services_background2.png')} alt="" /> */}
                    <div className="stage stage1 tppart dwn">
                        <p className="stgCircle">1</p>
                        <p data-aos-once="true" data-aos="fade-down" data-aos-duration="2000" className="stgText">Client brief <br /> Agreed</p>
                    </div>
                    <div className="stage stage2 tppart up">
                        <p className="stgCircle">2</p>
                        <p data-aos-once="true" data-aos="fade-up" data-aos-duration="2000" className="stgText">Search strategy Formulated</p>
                    </div>
                    <div className="stage stage3 tppart dwn">
                        <p className="stgCircle">3</p>
                        <p data-aos-once="true" data-aos="fade-down" data-aos-duration="2000" className="stgText">Candidate longlist developed</p>
                    </div>
                    <div className="stage stage4 tppart up">
                        <p className="stgCircle">4</p>
                        <p data-aos-once="true" data-aos="fade-up" data-aos-duration="2000" className="stgText">Candidate Suitability Commitment</p>
                    </div>
                    <div className="stage stage5 tppart dwn">
                        <p className="stgCircle">5</p>
                        <p data-aos-once="true" data-aos="fade-down" data-aos-duration="2000" className="stgText">Present candidate shortlist to client</p>
                    </div>
                    <div className="stage stage6 btmpart dwn">
                        <p className="stgCircle">6</p>
                        <p data-aos-once="true" data-aos="fade-down" data-aos-duration="2000" className="stgText">Arrange interviews & brief candidates</p>
                    </div>
                    <div className="stage stage7 btmpart up">
                        <p className="stgCircle">7</p>
                        <p data-aos-once="true" data-aos="fade-up" data-aos-duration="2000" className="stgText">Final selection & candidate Management</p>
                    </div>
                    <div className="stage stage8 btmpart dwn">
                        <p className="stgCircle">8</p>
                        <p data-aos-once="true" data-aos="fade-down" data-aos-duration="2000" className="stgText">Office process & References</p>
                    </div>
                    <div className="stage stage9 btmpart up">
                        <p className="stgCircle">9</p>
                        <p data-aos-once="true" data-aos="fade-up" data-aos-duration="2000" className="stgText">Agree start date & finalise contract</p>
                    </div>
                    <div className="stage stage10 btmpart dwn">
                        <p className="stgCircle">10</p>
                        <p data-aos-once="true" data-aos="fade-down" data-aos-duration="2000" className="stgText">Feedback and regular contract</p>
                    </div>
                </section>
                <div data-aos="zoom-in-up" data-aos-duration="1000" className="stgBtn">10 Stage Recruitment Process</div>
            </section>
            {/* 10 Stage Process Mobile */}
            <section className="process py-5 d-sm-block d-md-none">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle stgs">What to expect during Our Hiring Process</h3>
                <section className="stages container">
                    {/* <img src={require('./img/services_background2.png')} alt="" /> */}
                    <div className="stage stage1 tppart dwn">
                        <p className="stgCircle">1</p>
                        <p data-aos-once="true" className="stgText">Client brief <br /> Agreed</p>
                    </div>
                    <div className="stage stage2 tppart up">
                        <p className="stgCircle">2</p>
                        <p data-aos-once="true" className="stgText">Search strategy Formulated</p>
                    </div>
                    <div className="stage stage3 tppart dwn">
                        <p className="stgCircle">3</p>
                        <p data-aos-once="true" className="stgText">Candidate longlist developed</p>
                    </div>
                    <div className="stage stage4 tppart up">
                        <p className="stgCircle">4</p>
                        <p data-aos-once="true" className="stgText">Candidate Suitability Commitment</p>
                    </div>
                    <div className="stage stage5 tppart dwn">
                        <p className="stgCircle">5</p>
                        <p data-aos-once="true" className="stgText">Present candidate shortlist to client</p>
                    </div>
                    <div className="stage stage6 dwn">
                        <p className="stgCircle">6</p>
                        <p data-aos-once="true" className="stgText">Arrange interviews & brief candidates</p>
                    </div>
                    <div className="stage stage7 up">
                        <p className="stgCircle">7</p>
                        <p data-aos-once="true" className="stgText">Final selection & candidate Management</p>
                    </div>
                    <div className="stage stage8 dwn">
                        <p className="stgCircle">8</p>
                        <p data-aos-once="true" className="stgText">Office process & References</p>
                    </div>
                    <div className="stage stage9 up">
                        <p className="stgCircle">9</p>
                        <p data-aos-once="true" className="stgText">Agree start date & finalise contract</p>
                    </div>
                    <div className="stage stage10 dwn">
                        <p className="stgCircle">10</p>
                        <p data-aos-once="true" className="stgText">Feedback and regular contract</p>
                    </div>
                </section>
                <div data-aos="zoom-in-up" data-aos-duration="1000" className="stgBtn">10 Stage Recruitment Process</div>
            </section>
            {/* Client needs */}
            <section className="needs py-5 px-5">
                <div data-aos="zoom-in" data-aos-duration="500" className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="sect sect1">
                                <h3>Understand Client Needs</h3>
                                <p>- Consultation: Discuss hiring requirements and company culture.</p>
                                <p>- Job Specification: Help create a detailed job description.</p>
                                <p>- Sourcing Candidates</p>
                            </div>
                            <div className="sect sect2 mt-5">
                                <h3>Present Shortlist: Provide qualified candidates to the client.</h3>
                                <p>- <span>Facilitate Interviews:</span><span>Coordinate interviews between
                                    candidates and the client.</span></p>
                                <p>- <span className="nobold">Assessment and Selection</span><span></span></p>
                                <p>- <span>Skills Testing:</span><span>Recommend assessment tools if needed.</span></p>
                                <p>- <span>Feedback Loop:</span><span>Gather feedback from clients and candidates.
                                    Offer Management</span></p>
                                <p>- <span>Negotiate Offers:</span><span>Assist with salary and benefits negotiations.</span></p>
                                <p>- <span>Communication:</span><span>Ensure clear communication about offers.
                                    Onboarding Support</span></p>
                                <p>- <span>Transition Assistance:</span><span>Guide the onboarding process.</span></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="sect sect3">
                                <h3>Market Research: Identify talent pools.</h3>
                                <p>- Candidate Outreach: Use job boards, social media, and networks.</p>
                                <p>- Screening Process</p>
                                <p>- Initial Screening: Review resumes and applications.</p>
                                <p>- Interviews: Conduct preliminary interviews to assess fit.</p>
                                <p>- Client Collaboration</p>
                            </div>
                            <div className="sect sect4 mt-4">
                                <h3>Follow-Up</h3>
                                <p>- Check in post-hire for a smooth transition.</p>
                                <img data-aos="zoom-in-up" data-aos-duration="1000" src={require('./img/follow_up.webp')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefits py-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Benefits to the Client</h3>
                <div className="container pt-4">
                    <div data-aos="zoom-in-up" data-aos-duration="1000" className="row logos">
                        <div data-aos="fade-right" data-aos-duration="500" className="col-md-3">
                            <img src={require('./img/be1.webp')} alt="" />
                            <h3>Timesaving</h3>
                            <p>Efficient processes reduce the overall hiring time.</p>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="1000" className="col-md-3">
                            <img src={require('./img/be2.webp')} alt="" />
                            <h3>Cost-Effective</h3>
                            <p>Fewer hiring mistakes lead to lower costs in the long run.</p>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="1500" className="col-md-3">
                            <img src={require('./img/be3.webp')} alt="" />
                            <h3>Expertise</h3>
                            <p>Access to specialized recruitment knowledge enhances candidate quality.</p>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="2000" className="col-md-3">
                            <img src={require('./img/be4.webp')} alt="" />
                            <h3>Reduced Turnover</h3>
                            <p>Better candidate fit results in increased retention rates.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="vision hiringBg d-none d-md-flex">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/joinus2.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent">
                    <h2 data-aos="fade-up" className="py-5" style={{ color: '#fff', fontWeight: "600", fontSize: "35px" }}>With Right Careers, you're not just hiring –<br /> you're investing in the future of your business.</h2>
                </div>
            </section>
            {/* vision mob */}
            <section className="vision hiringBg d-sm-block d-md-none">
                <div className="visionContent">
                    <h2 data-aos="fade-up" className="py-5" style={{ color: '#fff', fontWeight: "600", fontSize: "35px" }}>With Right Careers, you're not just hiring –<br /> you're investing in the future of your business.</h2>
                </div>
            </section>
            <seciton className="srvcType">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="py-5 text-center stgs sectTitle">Types of Services Offered by Right Careers</h3>
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-md-6">
                            <img data-aos="zoom-in-up" data-aos-duration="2000" src={require('./img/srvcType.webp')} className="" alt="" />
                        </div>
                        <div className="col-md-6">
                            <ServiceTypes />
                        </div>
                    </div>
                </div>
            </seciton>
            <Footer />
        </>
    )

}