import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Testimonial = () => {
    const options = {
        items: 2,
        slideBy: 1,
        dotsEach: true,
        autoplay: true,
        autoplayTimeout: 5000,
        loop: true,
        responsive: {
            0 : {
                items : 1,
                slideBy : 1
            },
            420 : {
                items : 2,
                slideBy : 1
            }
        }
    }

    return (
        <>
            <section className="testimonialSect py-5">
                <h3 className="text-center sectTitle">Testimonials</h3>
                <div className="container testCards pt-5">
                    <div className="row justify-content-center">
                        {/* <Slider {...testimonial} style={{ maxWidth: '85%' }} className="testCards">
                        <div className="card card1 col-md-5 mx-2">
                            <h5>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                        <div className="card card2 col-md-5 mx-2">
                            <h4>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                        <div className="card card2 col-md-5 mx-2">
                            <h4>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                        <div className="card card2 col-md-5 mx-2">
                            <h4>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                        <div className="card card2 col-md-5 mx-2">
                            <h4>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                    </Slider> */}
                        <OwlCarousel className="owl-theme" {...options}>
                            <div className="card card1 mx-2 px-5 py-4">
                                <p className="words">Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</p>
                                <div className="clientInfo">
                                    <h4>Jennifer Issac</h4>
                                    <p>- Director | Crossasyst Infotech Private Limited</p>
                                </div>
                                <img src={require('./img/Testi_imgs/logo1.png')} alt="" className="cardImg"></img>
                            </div>
                            <div className="card card2 mx-2 px-5 py-4">
                                <p className="words">I feel compelled to write to you to say what a refreshing experience it has been dealing with you and your firm. The whole process of recruiting it positions in the business was effortless from start to end.</p>
                                <div className="clientInfo">
                                    <h4>Sagar Khanna</h4>
                                    <p>- Manager | Billdesk</p>
                                </div>
                                <img src={require('./img/Testi_imgs/logo2.png')} alt="" className="cardImg"></img>
                            </div>
                            <div className="card card1 mx-2 px-5 py-4">
                                <p className="words">Right careers just found the right candidates for us! The whole process was quick and really managed well. Kudos!!!</p>
                                <div className="clientInfo">
                                    <h4>Valencia Pereira</h4>
                                    <p>- Office Manager | Snipp Interactive India Pvt. Ltd</p>
                                </div>
                                <img src={require('./img/Testi_imgs/logo3.png')} alt="" className="cardImg"></img>
                            </div>
                            <div className="card card2 mx-2 px-5 py-4">
                                <p className="words">Right careers consistently delivers great results for us. Their friendly and professional attitude makes them a pleasure to deal with!</p>
                                <div className="clientInfo">
                                    <h4>Yash Wagh</h4>
                                    <p>- Vice President | Human Capital Winjit</p>
                                </div>
                                <img src={require('./img/Testi_imgs/logo4.png')} alt="" className="cardImg"></img>
                            </div>
                            <div className="card card2 mx-2 px-5 py-4">
                                <p className="words">Right careers are always so helpful whenever we have a role to fill. Neha & her team works really hard to find us the right candidate and even though our roles can be very specialized, she succeeds in finding the right person for the job. Whenever we have a vacancy to fill i do not hesitate to ask right careers!</p>
                                <div className="clientInfo">
                                    <h4>Pushkar Penkarhr</h4>
                                    <p>- Manager | Wysetek Systems Tech. Pvt. Ltd</p>
                                </div>
                                <img src={require('./img/Testi_imgs/logo5.png')} alt="" className="cardImg"></img>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    );
}