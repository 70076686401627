import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './joinus.css';
import BasicExample from "./BasicExample.jsx";
import { Footer } from "./Footer";

export const JoinUs = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    const handleSubmit = (e) => {
        e.preventDefault()
    }
    return (
        <>
            <Header />
            <div className="banner bg-img-join">
                <div className="bdcrumb"><Link to="/">Home</Link> | <Link to="#">About</Link> | Join Us</div>
                <span className="sm-highlight"> Join Us</span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">We are Who We Hire</h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            <section ref={ref} style={{ backgroundColor: '#ef8f22' }} className='p-5 w80 sect-overview joinSect'>
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className='container text-center dark content'>
                    At Right Careers, we foster a dynamic and inclusive<br /> culture where every team member thrives.
                </h3>
            </section>
            <section className="values py-5">
                <h3 data-aos="fade-up" className="text-center pb-4 mb-3 sectTitle">Our Values</h3>
                <div data-aos="fade-up" className="container valuesSect">
                    <div data-aos="fade-right" data-aos-duration="800" className="text-center boxes px-5">
                        <img src={require('./img/icons-inner-page/integrity.png')} alt="Right careers services" />
                        <h3 className="text-center">Integrity</h3>
                    </div>
                    <div Integrity data-aos-duration="800" className="text-center boxes px-5">
                        <img src={require('./img/icons-inner-page/innovation.png')} alt="Right careers services" />
                        <h3 className="text-center">Innovation</h3>
                    </div>
                    <div data-aos="fade-right" data-aos-duration="800" className="text-center boxes px-5">
                        <img src={require('./img/icons-inner-page/collabration.png')} alt="Right careers services" />
                        <h3 className="text-center">Collaboration</h3>
                    </div>
                </div>
                <div className="container py-2">
                    <p data-aos="fade-up" data-aos-duration="1000" className="text-center">We believe in transparent communication, empowering leadership, and a workplace prioritizing professional growth and personal well-being.</p>
                    <p data-aos="fade-up" data-aos-duration="1200" className="text-center">Employees are encouraged to engage fully, contribute creatively, and grow through continuous learning. With a focus on diversity, inclusion, and work-life balance, Right Careers offers a fulfilling career path, where talent is nurtured and opportunities for advancement are always within reach.</p>
                </div>
            </section>
            <seciton className="vision join flex-column d-none d-md-flex">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/join.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent">
                    <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center pb-4 sectTitle">Our Investment is You</h3>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">As a people-centric organization, your well-being is our top priority—physically, mentally, professionally, financially, and socially. We go beyond offering perks and benefits by providing access to the resources you need to live and work in a way that motivates you to excel for yourself and those around you.</p>
                </div>
            </seciton>
            <seciton className="vision join d-sm-flex d-md-none flex-column">
                <div className="visionContent">
                    <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center pb-4 sectTitle">Our Investment is You</h3>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">As a people-centric organization, your well-being is our top priority—physically, mentally, professionally, financially, and socially. We go beyond offering perks and benefits by providing access to the resources you need to live and work in a way that motivates you to excel for yourself and those around you.</p>
                </div>
            </seciton>
            <section className="faq">
                <div className="container py-5">
                    <h3 className="text-center pb-4 mb-3 sectTitle">Where Values Drive Success</h3>
                    <BasicExample />
                </div>
            </section>
            <section data-aos="zoom-in-up" data-aos-offset="300" data-aos-duration="1000" className="form py-5 d-none d-md-block">
                <div className="container formContainer py-4">
                    <h3 className="text-center mb-3 sectTitle"> Join us to be part of a culture that <br /> values your potential and promotes success.</h3>
                    <div className="text-center">
                        <form className="text-center joinUs" onSubmit={handleSubmit} action="">
                            <input placeholder="Name" className="inpt" type="text" />
                            <input placeholder="Last Name" className="inpt" type="text" />
                            <input placeholder="Email Id" className="inpt" type="text" />
                            <input placeholder="Contact Number" className="inpt" type="text" />
                            <input placeholder="Job Title Applied For" className="inpt" type="text" />
                            <div className="inpt fileUpload"><span>Upload Resume <input placeholder="Job Title Applied For" className="file" type="file" /></span></div>
                            <button className="submitBtn" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
            <section className="form py-5 d-sm-block d-md-none">
                <div className="container formContainer py-4">
                    <h3 className="text-center mb-3 sectTitle"> Join us to be part of a culture that <br /> values your potential and promotes success.</h3>
                    <div className="text-center">
                        <form className="text-center joinUs" onSubmit={handleSubmit} action="">
                            <input placeholder="Name" className="inpt" type="text" />
                            <input placeholder="Last Name" className="inpt" type="text" />
                            <input placeholder="Email Id" className="inpt" type="text" />
                            <input placeholder="Contact Number" className="inpt" type="text" />
                            <input placeholder="Job Title Applied For" className="inpt" type="text" />
                            <div className="inpt fileUpload"><span>Upload Resume <input placeholder="Job Title Applied For" className="file" type="file" /></span></div>
                            <button className="submitBtn" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}