export default function Servicesect() {
    return (
        <>
            <section className="service-sect d-none d-md-block">
                <video className='videoBg' autoPlay muted loop>
                    <source src={require('./video/our_services_home.mp4')} type="video/mp4" />
                </video>
                <div className="container videoContent py-5 px-3">
                    <div className="innerContent">
                        <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-once="true" className="text-center py-3 sectTitle">Our Services</h3>
                        <div className="row pt-5" data-aos="fade-up" data-aos-once="true">
                            <div className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/recruit_without_bg.webp')} alt="Right careers services" />
                                <h3 className="text-center">Recruitment</h3>
                                <p>We specialize in identifying and attracting top talent tailored to meet your organization's specific needs, ensuring the perfect fit for your team.</p>
                            </div>
                            <div className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/full_time.webp')} alt="Right careers services" />
                                <h3>Full-Time Employment</h3>
                                <p>Our dedicated team connects you with candidates seeking long-term career opportunities, providing a seamless hiring experience.</p>
                            </div>
                            <div className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/permanent.webp')} alt="Right careers services" />
                                <h3>Permanent Contract</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                            <div className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/icons-inner-page/R2R.png')} alt="Right careers services" />
                                <h3>Recruit the Recruiter (R2R)</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                            <div className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/icons-inner-page/C2C.png')} alt="Right careers services" />
                                <h3>Contract-to-Hire (C2H)</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                        </div>
                        <div className="text-center cta-btn pt-4 pb-3">
                            <button className='br-none servicBtn'><a href='/services' >Read More</a></button>
                        </div>
                    </div>
                </div>
            </section>
            {/* Mobile Sect. */}
            <section className="service-sect d-sm-block d-md-none">
                <div className="container videoContent px-0">
                    <div className="innerContent py-4">
                        <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-once="true" className="text-center sectTitle">Our Services</h3>
                        <div className="pt-4 px-3" data-aos="fade-right" data-aos-duration="1500" data-aos-once="true">
                            <div className="col-md-4 col-xs-12 text-center boxes py-3">
                                <img src={require('./img/recruit_without_bg.webp')} alt="Right careers services" />
                                <h3 className="text-center">Recruitment</h3>
                                <p>We specialize in identifying and attracting top talent tailored to meet your organization's specific needs, ensuring the perfect fit for your team.</p>
                            </div>
                            <div className="col-md-4 col-xs-12 text-center boxes py-3">
                                <img src={require('./img/full_time.webp')} alt="Right careers services" />
                                <h3>Full-Time Employment</h3>
                                <p>Our dedicated team connects you with candidates seeking long-term career opportunities, providing a seamless hiring experience.</p>
                            </div>
                            <div className="col-md-4 col-xs-12 text-center boxes py-3">
                                <img src={require('./img/permanent.webp')} alt="Right careers services" />
                                <h3>Permanent Contract</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                            <div className="col-md-4 col-xs-12 text-center boxes py-3">
                                <img src={require('./img/icons-inner-page/R2R.png')} alt="Right careers services" />
                                <h3>Recruit the Recruiter (R2R)</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                            <div className="col-md-4 col-xs-12 text-center boxes py-3">
                                <img src={require('./img/icons-inner-page/C2C.png')} alt="Right careers services" />
                                <h3>Contract-to-Hire (C2H)</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                        </div>
                        <div className="text-center cta-btn pt-4 pb-3">
                            <button className='br-none servicBtn'><a href='/services' >Read More</a></button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
