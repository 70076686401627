import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer.js";
import './services.css'
import './Industrial.css'
import './industries.css'
import ServiceTypes from "./ServiceTypes.jsx";
import Cards from "./Cards.jsx"
import { Testimonial } from "./Testimonial.js";

export const Industrial_Engineering = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg_ind">
                <div className="bdcrumb"><a href="/">Home</a> | Industries | Industrial & Engineering </div>
                <span className="sm-highlight">Industrial & Engineering </span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Your Partner in Building  <br /><span className="subhead" style={{ fontWeight: 400 }}>Tomorrow’s Industrial Workforce</span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* about overview section on home page */}
            <section className="clients my-5" ref={ref}>
                <div className="industry headings">
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="mx-auto industrial mytext">In the dynamic Automation, Industrial Products, and Machine Tools sector, it is important to find top-tier talent with leading organizations. This industry demands a skilled workforce adept at navigating advanced technologies and complex manufacturing processes.</h2>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">At Right Careers, we specialize in recruiting professionals who possess both technical expertise and a forward-thinking mindset essential for driving operational excellence. From engineers to project managers, our extensive network enables us to source candidates who align with your company's vision and culture.</p>
                    <p data-aos="fade-up" data-aos-duration="1000" className="mt-3">Whether you need permanent hires or temporary staffing solutions, Right Careers is committed to providing the talent necessary to thrive in this competitive market.
                    </p>
                </div>
            </section>
            <section className="indSrvc pt-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Services</h3>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon1.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Talent Acquisition</h5>
                                    <p>Identifying and attracting top candidates to fit organizational roles.&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon2.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Executive Search</h5>
                                    <p>Locating senior-level leaders with vision and strategic expertise.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon3.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Temporary Staffing</h5>
                                    <p>Providing flexible, short-term talent solutions for immediate business needs.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon4.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Recruitment Process 
                                    Outsourcing (RPO)</h5>
                                    <p>Managing the entire recruitment process, ensuring efficiency and quality hires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 right  rghtSect">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon5.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Employer Branding</h5>
                                    <p>Enhancing company reputation to attract and retain top talent.</p>
                                </div>
                            </div>
                            <img data-aos="fade-right" data-aos-duration="1000" className="lady" src={require('./img/industrial_serivce.webp')} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="staffing py-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Staffing Solutions</h3>
                <div className="container py-4">
                    <div className="cards">
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/industrial1.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Automation & Control Systems Engineering</h5>
                                <p>
                                    - Automation Engineer<br />
                                    - PLC Programmer<br />
                                    - Control Systems Engineer<br />
                                    - SCADA Engineer<br />
                                    - Instrumentation Engineer<br />
                                    - Robotics Engineer<br />
                                    - Industrial IoT (IIoT) Engineer
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/industrial2.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Mechanical & Industrial Engineering</h5>
                                <p>
                                    - Mechanical Design Engineer<br />
                                    - Manufacturing Engineer<br />
                                    - Process Engineer<br />
                                    - Tooling Engineer<br />
                                    - Production Engineer
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/industrial3.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Machine Tool Engineering & Design</h5>
                                <p>
                                    - Machine Tool Engineer<br />
                                    - CNC Programmer<br />
                                    - CNC Machine Operator<br />
                                    - Tool and Die Maker<br />
                                    - Maintenance Technician (Machine Tools)
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/industrial4.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Electrical & Electronics Engineering</h5>
                                <p>
                                    - Electrical Design Engineer<br />
                                    - Power Electronics Engineer<br />
                                    -  Embedded Systems Engineer
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="clients py-5">
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center sectTitle">Some of Our Clients</h3>
                <div className="text-center">
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/tatamotors.webp')} alt="" />
                </div>
            </section>
            <Testimonial />
            <section className="indstats">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/Indvid.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent indRows py-5">
                    <p data-aos="fade-right" data-aos-duration="500"><span className="boldText">5K+</span> Successful<br /> Recruits Yearly</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1000">Assured <span className="boldText">2:2</span><br />Hiring Plan </p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1500">Consistent Track record of<br /><span className="boldText">95%</span> On-Time Delivery</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="2000" className="nobr"><span className="boldText">Skill-Based Training</span><br /> with Certified Industry Experts</p>
                </div>
            </section>
            <Footer />
        </>
    )
}